.App {
  @apply bg-primary;
}

.fill {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation-name: Dash, FillIn;
    fill-opacity: 0;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-duration: 3s, 1s;
    animation-delay: 0s, 1.25s;
}

@keyframes Dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes FillIn {
    from {
        fill-opacity: 0;
    }
    to {
        fill-opacity: 1;
    }
}
